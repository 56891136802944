import React, { useEffect, useState, createContext, useCallback, useReducer } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useLocation } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import Mailbox from './components/Mailbox';
import { getValidToken } from './utils/tokenManager';
import Pricing from './components/Pricing';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { FaHome, FaEnvelope, FaRobot, FaCog, FaChartLine, FaPuzzlePiece, FaPlug, FaUserShield, FaChevronDown, FaChevronUp, FaLightbulb } from 'react-icons/fa';
import Apps from './components/Apps';
import Integrations from './components/Integrations';
import Enterprise from './components/Enterprise';
import Agents from './components/Agents';
import AddAgent from './components/AddAgent';
import Skills from './components/Skills';
import AgentPage from './components/AgentPage';
import WidgetPreview from './components/WidgetPreview'; // Add this import
import Admin from './components/Admin';
import axios from 'axios';

// Create a context for user info
export const UserContext = createContext(null);
export const UserDispatchContext = createContext();

function userReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_USER':
      return { ...state, ...action.payload };
    // ... other cases
    default:
      return state;
  }
}

// Define the initial user state
const initialUserState = {
  plan: null,
  planExpiry: null,
  subscriptionStatus: null,
  cancelAtPeriodEnd: null,
  email: null,
  // Add any other initial user properties you need
};

function App({ signOut, user }) {
  console.log('App user:', user);
  console.log({user});
  const [featureFlags, setFeatureFlags] = useState({});
  const [userState, dispatch] = useReducer(userReducer, initialUserState);
  const [agentsSubmenuOpen, setAgentsSubmenuOpen] = useState(false);

  const fetchUserInfo = useCallback(async () => {
    try {
      const token = await getValidToken();
      const response = await fetch(`${process.env.REACT_APP_USER_API_URL}/me`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user info');
      }
      const data = await response.json();
      dispatch({ type: 'UPDATE_USER', payload: data });
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  }, []);

  const fetchFeatureFlags = useCallback(async () => {
    try {
      const token = await getValidToken();
      const response = await axios.get(`${process.env.REACT_APP_USER_API_URL}/feature-flags`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setFeatureFlags(response.data);
    } catch (error) {
      console.error('Error fetching feature flags:', error);
    }
  }, []);

  useEffect(() => {
    fetchUserInfo();
    fetchFeatureFlags();
  }, [fetchUserInfo, fetchFeatureFlags]);

  const showMailbox = process.env.REACT_APP_FF_MAILBOX === '1';

  const isAdminUser = user?.username === '4408a428-f081-704d-7810-a3c37e038cc0';

  const refreshUserInfo = useCallback(async () => {
    await fetchUserInfo();
  }, [fetchUserInfo]);

  const Layout = ({ children }) => (
    <div className="flex min-h-screen bg-gray-100">
      <nav className="w-64 bg-navy-900 text-white p-6 flex flex-col">
        <div className="flex-grow">
          <div className="flex items-center mb-6">
            <div className="w-10 h-10 rounded-full overflow-hidden mr-3">
              <img src="/zapdroid.jpg" alt="Zapdroid logo" className="w-full h-full object-cover" />
            </div>
            <h2 className="text-2xl font-semibold">Zapdroid</h2>
          </div>
          <ul className="space-y-2">
            <li><NavLink to="/dashboard" icon={<FaHome />}>Dashboard</NavLink></li>
            <li><NavLink to="/apps" icon={<FaPuzzlePiece />}>Apps</NavLink></li>
            <li><NavLink to="/integrations" icon={<FaPlug />}>Integrations</NavLink></li>
            {showMailbox && <li><NavLink to="/mailbox" icon={<FaEnvelope />}>Mailbox</NavLink></li>}
            <li>
                <button 
                  onClick={() => setAgentsSubmenuOpen(!agentsSubmenuOpen)}
                  className="w-full flex items-center justify-between py-2 px-4 rounded transition duration-150 ease-in-out text-navy-100 hover:bg-navy-800 hover:text-white"
                >
                  <span className="flex items-center">
                    <FaRobot className="mr-2" />
                    Agents
                  </span>
                  {agentsSubmenuOpen ? <FaChevronUp /> : <FaChevronDown />}
                </button>
                {agentsSubmenuOpen && (
                  <ul className="ml-4 mt-2 space-y-2">
                    <li><NavLink to="/agents" icon={<FaRobot />}>Manage Agents</NavLink></li>
                    <li><NavLink to="/agents/skills" icon={<FaLightbulb />}>Skills</NavLink></li>
                  </ul>
                )}
            </li>
            <li><NavLink to="/pricing" icon={<FaChartLine />}>Plan</NavLink></li>
            <li><NavLink to="/settings" icon={<FaCog />}>Settings</NavLink></li>
            {isAdminUser && (
              <li><NavLink to="/admin" icon={<FaUserShield />}>Admin</NavLink></li>
            )}
          </ul>
          <button onClick={signOut} className="mt-8 w-full bg-red-400 hover:bg-red-500 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out">Sign out</button>
        </div>
        <div className="mt-auto pt-4 text-xs text-center text-navy-100">
          &copy; {new Date().getFullYear()} Zapdroid LLC
        </div>
      </nav>
      <main className="flex-1 p-10 overflow-auto">
        {children}
      </main>
    </div>
  );

  return (
    <Router>
      <ReactNotifications />
      <UserContext.Provider value={userState}>
        <UserDispatchContext.Provider value={dispatch}>
          <Routes>
            <Route path="/agents/:agent_id/widget_preview" element={<WidgetPreview />} />
            <Route
              path="*"
              element={
                <Layout>
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard user={user} />} />
                    <Route path="/apps" element={<Apps />} />
                    <Route path="/integrations" element={<Integrations />} />
                    <Route path="/integrations/mailbox" element={<Mailbox />} />
                    <Route path="/settings" element={<Settings user={user} refreshUserInfo={fetchUserInfo} />} />
                    <Route path="/pricing" element={<Pricing refreshUserInfo={refreshUserInfo} />} />
                    <Route path="/enterprise" element={<Enterprise user={user} />} />
                    <Route path="/agents" element={<Agents user={user} />} />
                    <Route path="/agents/add" element={<AddAgent />} />
                    <Route path="/agents/skills" element={<Skills />} />
                    <Route path="/agents/:id" element={<AgentPage />} />
                    {isAdminUser && (
                      <Route path="/admin" element={<Admin user={user} />} />
                    )}
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                  </Routes>
                </Layout>
              }
            />
          </Routes>
        </UserDispatchContext.Provider>
      </UserContext.Provider>
    </Router>
  );
}

function NavLink({ to, children, icon, disabled }) {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  const content = (
    <>
      {icon && <span className="mr-2">{icon}</span>}
      {children}
    </>
  );

  if (disabled) {
    return (
      <span className="block py-2 px-4 rounded text-gray-500 cursor-not-allowed flex items-center">
        {content}
      </span>
    );
  }

  return (
    <Link 
      to={to} 
      className={`block py-2 px-4 rounded transition duration-150 ease-in-out flex items-center ${
        isActive 
          ? 'bg-cyan-500 text-white font-semibold' 
          : 'text-navy-100 hover:bg-navy-800 hover:text-white'
      }`}
    >
      {content}
    </Link>
  );
}

export default withAuthenticator(App);